import { Box, Link } from "@mui/material";
import { ListItemText } from "@mui/material";
import { ListItem } from "@mui/material";
import { List } from "@mui/material";
import { Typography } from "@mui/material";
import { Paper } from "@mui/material";
import { Order } from "../../../types/admin/orders";
import { parseHTMLString } from "../../../utils/constants";

export default function ItemsInfo({ order }: { order: Order }) {
  console.log(order.items);
  return (
    <>
      <Paper
        elevation={2}
        sx={{
          p: 3,
          borderRadius: 2,
          border: "1px solid #e0e0e0",
        }}
      >
        <Typography
          variant="h6"
          component="div"
          sx={{ mb: 3, fontWeight: 500 }}
        >
          Items
        </Typography>
        <List
          sx={{
            bgcolor: "#fafafa",
            borderRadius: 1,
            mb: 2,
          }}
        >
          {order.items.map((item) => (
            <ListItem
              key={item.id}
              sx={{
                borderBottom: "1px solid #e0e0e0",
                "&:last-child": {
                  borderBottom: "none",
                },
              }}
            >
              <ListItemText
                primary={
                  <Typography sx={{ fontWeight: 500, mb: 1 }}>
                    {parseHTMLString(item.title)}
                  </Typography>
                }
                secondary={
                  <>
                    <Typography
                      component="span"
                      variant="body1"
                      color="text.secondary"
                      sx={{ display: "block", mb: 0.5 }}
                    >
                      Description: {parseHTMLString(item.description)}
                    </Typography>
                    <Typography
                      component="span"
                      variant="body1"
                      color="text.secondary"
                      sx={{ display: "block", mb: 1 }}
                    >
                      <Link
                        href={`/item/${item.id}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Reference: {item.reference}
                      </Link>
                    </Typography>
                    <Typography
                      component="span"
                      variant="body1"
                      color="text.secondary"
                      sx={{ display: "block", mb: 0.5 }}
                    >
                      Is Sheet: {item.isSheet ? "Yes" : "No"}
                    </Typography>
                    <Typography
                      component="span"
                      variant="subtitle1"
                      sx={{
                        display: "block",
                        fontWeight: 500,
                        color: "primary.main",
                      }}
                    >
                      £{item.price.toFixed(2)}
                    </Typography>
                  </>
                }
              />
            </ListItem>
          ))}
        </List>
        <Paper
          elevation={0}
          sx={{
            bgcolor: "#f5f5f5",
            p: 2,
            borderRadius: 1,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 1,
            }}
          >
            <Typography variant="subtitle1" fontWeight={500}>
              Total Shipping
            </Typography>
            <Typography variant="subtitle1" fontWeight={500}>
              £{Number(order.postage).toFixed(2)}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              pt: 1,
              borderTop: "1px solid #e0e0e0",
            }}
          >
            <Typography variant="subtitle1" fontWeight={700}>
              Total Cost
            </Typography>
            <Typography variant="subtitle1" fontWeight={700}>
              £{(Number(order.totalCost) + Number(order.postage)).toFixed(2)}
            </Typography>
          </Box>
        </Paper>
      </Paper>
    </>
  );
}
