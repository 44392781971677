import {
  Button,
  FormControl,
  FormControlLabel,
  OutlinedInput,
  Radio,
  RadioGroup,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  Paper,
  InputLabel,
  Box,
  Tooltip,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import styles from "./Orders.module.css";
import Loading from "../../../components/Loader/Loader";
import {
  DataGrid,
  GridColDef,
  GridPaginationModel,
  GridRenderCellParams,
} from "@mui/x-data-grid";
import GenerateInvoice from "../../../components/GenerateInvoice/GenerateInvoice";
import { pdf } from "@react-pdf/renderer";
import PrintOrder from "../../../components/PrintOrder/PrintOrder";
import PrintLabel from "../../../components/PrintLabel/PrintLabel";
import { AppConfig } from "../../../utils/config";
import dayjs from "dayjs";
import { getAuth } from "firebase/auth";
import { fetchWithTokenCheck } from "../../../utils/utils";
import TransactionInfo from "../../../components/Admin/Orders/TransactionInfo";
import { Order } from "../../../types/admin/orders";
import ItemsInfo from "../../../components/Admin/Orders/ItemsInfo";

type SearchOrderValue = "orderNum" | "customerName" | "itemReference";

const searchOrderMaps: Record<SearchOrderValue, string> = {
  orderNum: "order",
  customerName: "name",
  itemReference: "reference",
};

export default function Orders() {
  const searchRef = useRef<HTMLInputElement>(null);
  const [searchResults, setSearchResults] = useState<Order[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [page, setPage] = useState<number>(0);
  const [orders, setOrders] = useState<Order[]>([]);
  const [selectedOrder, setSelectedOrder] = useState<Order | null>(null);
  const [orderViewOpen, setOrderViewOpen] = useState(false);
  const [pageSize, setPageSize] = useState<number>(100);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [searchOrderValue, setSearchOrderValue] =
    useState<SearchOrderValue>("orderNum");

  const handleSearchOrderChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSearchOrderValue(event.target.value as SearchOrderValue);
  };

  function fetchOrders() {
    setLoading(true);
    const auth = getAuth();
    const searchVal = searchRef.current?.value.toLowerCase();
    const searchFilter = searchOrderMaps[searchOrderValue];
    setSearchResults([]);

    fetchWithTokenCheck(
      {
        method: "GET",
        url: `${AppConfig.apiUrl}/orders?page=${page + 1}${
          searchVal && `&${searchFilter}=${searchVal}`
        }`,
      },
      auth
    ).then((res) => {
      setOrders(res.data.orders);
      setTotalCount(res.data.totalCount);
      setLoading(false);
    });
  }

  const handleSearch = () => {
    const searchVal = searchRef.current?.value.toLowerCase();
    setSearchResults([]);
    if (searchVal) {
      const searchFilter = searchOrderMaps[searchOrderValue];
      setLoading(true);
      const auth = getAuth();
      fetchWithTokenCheck(
        {
          method: "GET",
          url: `${AppConfig.apiUrl}/orders?${searchFilter}=${searchVal}`,
        },
        auth
      )
        .then((res) => {
          setSearchResults(res.data.orders);
          setTotalCount(res.data.totalCount);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          console.warn(err);
        });
    } else {
      fetchOrders();
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };

  useEffect(() => {
    fetchOrders();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const columns: GridColDef[] = [
    {
      field: "orderNo",
      headerName: "Order Number",
      width: 150,
      sortable: false,
      filterable: false,
    },
    {
      field: "Full Name",
      headerName: "Customer Name",
      sortable: false,
      filterable: false,
      width: 200,
      valueGetter: (params: GridRenderCellParams<Order>) =>
        `${params.row.customer.title} ${params.row.customer.name} ${params.row.customer.lastName}`,
    },

    {
      field: "email",
      headerName: "E-mail address",
      width: 250,
      sortable: false,
      filterable: false,
      valueGetter: (params: GridRenderCellParams<Order>) =>
        params.row.customer.email,
    },
    {
      field: "orderDate",
      headerName: "Order Date",
      width: 150,
      sortable: false,
      filterable: false,
      valueGetter: (params: GridRenderCellParams<Order>) => {
        return dayjs(params.row.orderDate).format("DD-MM-YYYY");
      },
    },
    {
      field: "Items",
      headerName: "Items",
      width: 200,
      sortable: false,
      filterable: false,
      renderCell: (params: GridRenderCellParams<Order>) => {
        const references = params.row.items
          .map((item) => item.reference)
          .join(", ");
        return (
          <Tooltip title={references}>
            <Typography
              variant="body2"
              sx={{
                fontSize: "0.875rem",
                fontWeight: 400,
                fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                lineHeight: "1.43",
                letterSpacing: "0.01071em",
                color: "inherit",
              }}
              noWrap
            >
              {references}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      field: "totalCost",
      headerName: "Total Cost",
      sortable: false,
      filterable: false,
      valueGetter: (params: GridRenderCellParams<Order>) => {
        const total = Number(params.row.totalCost) + Number(params.row.postage);
        return `£${Number(total).toFixed(2)}`;
      },
    },
  ];

  const handleRowClick = async (params: any) => {
    const selectedOrder =
      searchResults.length === 0
        ? orders.find((order: Order) => order.orderNo === params)
        : searchResults.find((a) => a.orderNo === params);
    setSelectedOrder(selectedOrder || null);
    //Update to new fetch once everything else is organised
  };

  const useOrderNoAsId = (row: Order) => {
    return row.orderNo;
  };

  const handlePaginationModelChange = (
    paginationModel: GridPaginationModel
  ) => {
    setPage(paginationModel.page);
    setPageSize(paginationModel.pageSize);
  };

  const handleUpdateMenu = () => {
    setOrderViewOpen(!orderViewOpen);
    //setUpdateCustomerValues(initialCustomerState);
  };

  const generateInvoice = async () => {
    if (selectedOrder) {
      const blob = await pdf(<GenerateInvoice data={selectedOrder} />).toBlob();
      const url = URL.createObjectURL(blob);
      window.open(url);
    }
  };

  const printOrder = async () => {
    if (selectedOrder) {
      const blob = await pdf(<PrintOrder data={selectedOrder} />).toBlob();
      const url = URL.createObjectURL(blob);
      window.open(url);
    }
  };

  const printLabel = async () => {
    if (selectedOrder) {
      const blob = await pdf(<PrintLabel data={selectedOrder} />).toBlob();
      const url = URL.createObjectURL(blob);
      window.open(url);
    }
  };

  // TODO View orders by YEAR range (From To Default 8 years) Button
  // TODO Click here to enter free text order for a customer?
  // TODO List of orders

  return (
    <div className={styles.orderContainer}>
      <h2>View Order Summary</h2>
      <div>
        <h2>Search Orders</h2>
        <div>
          <OutlinedInput
            size="small"
            name="Search..."
            placeholder="Search..."
            inputRef={searchRef}
            onKeyDown={handleKeyDown}
          />
          <FormControl>
            <RadioGroup
              row
              value={searchOrderValue}
              onChange={handleSearchOrderChange}
              sx={{ px: 2 }}
            >
              <FormControlLabel
                value="orderNum"
                control={<Radio />}
                label="By order number"
              />
              <FormControlLabel
                value="customerName"
                control={<Radio />}
                label="By customer name"
              />
              <FormControlLabel
                value="itemReference"
                control={<Radio />}
                label="By item reference"
              />
            </RadioGroup>
          </FormControl>
          <Button variant="contained" onClick={handleSearch}>
            Search
          </Button>
        </div>
        <h2>Orders</h2>
      </div>
      {loading ? (
        <div className={styles.inlineLoader}>
          <Loading />
        </div>
      ) : (
        <>
          <div className={styles.customerTable}>
            {" "}
            <DataGrid
              getRowId={useOrderNoAsId}
              rows={
                searchResults && searchResults.length > 0
                  ? [...searchResults].sort((a, b) => b.orderNo - a.orderNo)
                  : [...orders].sort((a, b) => b.orderNo - a.orderNo)
              }
              columns={columns}
              pagination
              paginationMode="server"
              sortingMode="server"
              filterMode="server"
              rowCount={totalCount}
              loading={loading}
              paginationModel={{
                page: page,
                pageSize: pageSize,
              }}
              onRowClick={(params) => {
                handleRowClick(params.id);
                handleUpdateMenu();
              }}
              onPaginationModelChange={handlePaginationModelChange}
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: 100 },
                },
              }}
            />
          </div>
        </>
      )}

      {/* New Order Dialog */}
      <Dialog
        open={orderViewOpen}
        fullWidth
        maxWidth="xl"
        onClose={handleUpdateMenu}
        PaperProps={{
          sx: {
            borderRadius: 2,
            padding: 2,
            maxHeight: "90vh", // Limit dialog height
          },
        }}
      >
        <DialogTitle
          sx={{
            borderBottom: "1px solid #e0e0e0",
            pb: 2,
          }}
        >
          <Typography variant="h5" sx={{ mb: 2 }}>
            Order Details
          </Typography>
          <Box
            sx={{
              display: "flex",
              gap: 4,
              color: "text.secondary",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              <Typography variant="h6" color="text.secondary">
                Order Number:
              </Typography>
              <Typography variant="h6" fontWeight={500} color="text.primary">
                {selectedOrder?.orderNo}
              </Typography>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              <Typography variant="h6" color="text.secondary">
                Order Date:
              </Typography>
              <Typography variant="h6" fontWeight={500} color="text.primary">
                {dayjs(selectedOrder?.orderDate).format("DD/MM/YYYY")}
              </Typography>
            </Box>
          </Box>
        </DialogTitle>
        <DialogContent
          sx={{
            mt: 2,
            overflow: "auto", // Enable scrolling
          }}
        >
          <Grid container spacing={2} sx={{ flexGrow: 1 }}>
            {/* Customer and Shipping Details sections */}
            <Grid item xs={12} md={6}>
              <Paper
                elevation={3}
                sx={{
                  p: 2,
                  maxHeight: "60vh", // Limit height
                  overflow: "auto", // Enable scrolling
                }}
              >
                <Typography
                  variant="h6"
                  component="div"
                  sx={{ mb: 3, fontWeight: 500 }}
                >
                  Customer Details
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12}>
                    <FormControl fullWidth variant="outlined">
                      <InputLabel htmlFor="fullName" size="small">
                        Full Name
                      </InputLabel>
                      <OutlinedInput
                        id="fullName"
                        name="FullName"
                        size="small"
                        label="Full Name"
                        disabled
                        sx={{
                          "& .MuiOutlinedInput-input.Mui-disabled": {
                            WebkitTextFillColor: "black",
                            color: "black",
                          },
                        }}
                        value={`${selectedOrder?.customer.title} ${selectedOrder?.customer.name} ${selectedOrder?.customer.lastName}`}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <FormControl fullWidth variant="outlined">
                      <InputLabel htmlFor="email" size="small">
                        Email
                      </InputLabel>
                      <OutlinedInput
                        id="email"
                        name="Email"
                        size="small"
                        label="Email"
                        disabled
                        sx={{
                          "& .MuiOutlinedInput-input.Mui-disabled": {
                            WebkitTextFillColor: "black",
                            color: "black",
                          },
                        }}
                        value={selectedOrder?.customer.email}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <FormControl fullWidth variant="outlined">
                      <InputLabel htmlFor="address1" size="small">
                        Address Line 1
                      </InputLabel>
                      <OutlinedInput
                        id="address1"
                        name="Address1"
                        size="small"
                        label="Address Line 1"
                        disabled
                        sx={{
                          "& .MuiOutlinedInput-input.Mui-disabled": {
                            WebkitTextFillColor: "black",
                            color: "black",
                          },
                        }}
                        value={selectedOrder?.customer.address1}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <FormControl fullWidth variant="outlined">
                      <InputLabel htmlFor="address2" size="small">
                        Address Line 2
                      </InputLabel>
                      <OutlinedInput
                        id="address2"
                        name="Address2"
                        size="small"
                        label="Address Line 2"
                        disabled
                        sx={{
                          "& .MuiOutlinedInput-input.Mui-disabled": {
                            WebkitTextFillColor: "black",
                            color: "black",
                          },
                        }}
                        value={selectedOrder?.customer.address2}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <FormControl fullWidth variant="outlined">
                      <InputLabel htmlFor="town" size="small">
                        Town
                      </InputLabel>
                      <OutlinedInput
                        id="town"
                        name="Town"
                        size="small"
                        label="Town"
                        disabled
                        sx={{
                          "& .MuiOutlinedInput-input.Mui-disabled": {
                            WebkitTextFillColor: "black",
                            color: "black",
                          },
                        }}
                        value={selectedOrder?.customer.town}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <FormControl fullWidth variant="outlined">
                      <InputLabel htmlFor="county" size="small">
                        County
                      </InputLabel>
                      <OutlinedInput
                        id="county"
                        name="County"
                        size="small"
                        label="County"
                        disabled
                        sx={{
                          "& .MuiOutlinedInput-input.Mui-disabled": {
                            WebkitTextFillColor: "black",
                            color: "black",
                          },
                        }}
                        value={selectedOrder?.customer.county}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <FormControl fullWidth variant="outlined">
                      <InputLabel htmlFor="postcode" size="small">
                        Postcode
                      </InputLabel>
                      <OutlinedInput
                        id="postcode"
                        name="Postcode"
                        size="small"
                        label="Postcode"
                        disabled
                        sx={{
                          "& .MuiOutlinedInput-input.Mui-disabled": {
                            WebkitTextFillColor: "black",
                            color: "black",
                          },
                        }}
                        value={selectedOrder?.customer.postcode}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <FormControl fullWidth variant="outlined">
                      <InputLabel htmlFor="country" size="small">
                        Country
                      </InputLabel>
                      <OutlinedInput
                        id="country"
                        name="Country"
                        size="small"
                        label="Country"
                        disabled
                        sx={{
                          "& .MuiOutlinedInput-input.Mui-disabled": {
                            WebkitTextFillColor: "black",
                            color: "black",
                          },
                        }}
                        value={selectedOrder?.customer.country}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>

            <Grid item xs={12} md={6}>
              <Paper
                elevation={3}
                sx={{
                  p: 2,
                  maxHeight: "60vh", // Limit height
                  overflow: "auto", // Enable scrolling
                }}
              >
                <Typography
                  variant="h6"
                  component="div"
                  sx={{ mb: 3, fontWeight: 500 }}
                >
                  Shipping Details
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12}>
                    <FormControl fullWidth variant="outlined">
                      <InputLabel htmlFor="shippingFullName" size="small">
                        Full Name
                      </InputLabel>
                      <OutlinedInput
                        id="shippingFullName"
                        name="FullName"
                        size="small"
                        label="Full Name"
                        disabled
                        sx={{
                          "& .MuiOutlinedInput-input.Mui-disabled": {
                            WebkitTextFillColor: "black",
                            color: "black",
                          },
                        }}
                        value={`${selectedOrder?.customer.title} ${selectedOrder?.customer.name} ${selectedOrder?.customer.lastName}`}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <FormControl fullWidth variant="outlined">
                      <InputLabel htmlFor="shippingAddress1" size="small">
                        Address Line 1
                      </InputLabel>
                      <OutlinedInput
                        id="shippingAddress1"
                        name="Address1"
                        size="small"
                        label="Address Line 1"
                        disabled
                        sx={{
                          "& .MuiOutlinedInput-input.Mui-disabled": {
                            WebkitTextFillColor: "black",
                            color: "black",
                          },
                        }}
                        value={selectedOrder?.address1}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <FormControl fullWidth variant="outlined">
                      <InputLabel htmlFor="shippingAddress2" size="small">
                        Address Line 2
                      </InputLabel>
                      <OutlinedInput
                        id="shippingAddress2"
                        name="Address2"
                        size="small"
                        label="Address Line 2"
                        disabled
                        sx={{
                          "& .MuiOutlinedInput-input.Mui-disabled": {
                            WebkitTextFillColor: "black",
                            color: "black",
                          },
                        }}
                        value={selectedOrder?.address2}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <FormControl fullWidth variant="outlined">
                      <InputLabel htmlFor="shippingTown" size="small">
                        Town
                      </InputLabel>
                      <OutlinedInput
                        id="shippingTown"
                        name="Town"
                        size="small"
                        label="Town"
                        disabled
                        sx={{
                          "& .MuiOutlinedInput-input.Mui-disabled": {
                            WebkitTextFillColor: "black",
                            color: "black",
                          },
                        }}
                        value={selectedOrder?.town}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <FormControl fullWidth variant="outlined">
                      <InputLabel htmlFor="shippingCounty" size="small">
                        County
                      </InputLabel>
                      <OutlinedInput
                        id="shippingCounty"
                        name="County"
                        size="small"
                        label="County"
                        disabled
                        sx={{
                          "& .MuiOutlinedInput-input.Mui-disabled": {
                            WebkitTextFillColor: "black",
                            color: "black",
                          },
                        }}
                        value={selectedOrder?.county}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <FormControl fullWidth variant="outlined">
                      <InputLabel htmlFor="shippingPostcode" size="small">
                        Postcode
                      </InputLabel>
                      <OutlinedInput
                        id="shippingPostcode"
                        name="Postcode"
                        size="small"
                        label="Postcode"
                        disabled
                        sx={{
                          "& .MuiOutlinedInput-input.Mui-disabled": {
                            WebkitTextFillColor: "black",
                            color: "black",
                          },
                        }}
                        value={selectedOrder?.postcode}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <FormControl fullWidth variant="outlined">
                      <InputLabel htmlFor="shippingCountry" size="small">
                        Country
                      </InputLabel>
                      <OutlinedInput
                        id="shippingCountry"
                        name="Country"
                        size="small"
                        label="Country"
                        disabled
                        sx={{
                          "& .MuiOutlinedInput-input.Mui-disabled": {
                            WebkitTextFillColor: "black",
                            color: "black",
                          },
                        }}
                        value={selectedOrder?.country}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>

          {/* Items section with scroll */}
          <Grid item xs={12} sx={{ mt: 3 }}>
            {selectedOrder && <ItemsInfo order={selectedOrder} />}
          </Grid>

          {selectedOrder?.buyNotes && (
            <Grid item xs={12} sx={{ mt: 3 }}>
              <Paper
                elevation={2}
                sx={{
                  p: 3,
                  borderRadius: 2,
                  border: "1px solid #e0e0e0",
                }}
              >
                <Typography
                  variant="h6"
                  component="div"
                  sx={{ mb: 2, fontWeight: 500 }}
                >
                  Customer Notes
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    bgcolor: "#fafafa",
                    p: 2,
                    borderRadius: 1,
                  }}
                >
                  {selectedOrder.buyNotes}
                </Typography>
              </Paper>
            </Grid>
          )}

          <Grid item xs={12} sx={{ mt: 3 }}>
            {selectedOrder && <TransactionInfo order={selectedOrder} />}
          </Grid>
          <Grid item xs={12} sx={{ mt: 4 }}>
            <Grid container spacing={2} justifyContent="flex-end">
              <Grid item>
                <Button
                  onClick={printOrder}
                  variant="outlined"
                  sx={{ minWidth: "120px" }}
                >
                  Print Order
                </Button>
              </Grid>
              <Grid item>
                <Button
                  onClick={generateInvoice}
                  variant="outlined"
                  sx={{ minWidth: "120px" }}
                >
                  Generate Invoice
                </Button>
              </Grid>
              <Grid item>
                <Button
                  onClick={printLabel}
                  variant="outlined"
                  sx={{ minWidth: "120px" }}
                >
                  Generate Label
                </Button>
              </Grid>
              <Grid item>
                <Button
                  onClick={handleUpdateMenu}
                  variant="contained"
                  sx={{ minWidth: "120px" }}
                >
                  Close
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
}
