import {
  Typography,
  Card,
  CardContent,
  Grid,
  Box,
  capitalize,
} from "@mui/material";
import { Order } from "../../../types/admin/orders";
import dayjs from "dayjs";

export default function TransactionInfo({ order }: { order: Order }) {
  return (
    <Card
      elevation={2}
      sx={{
        p: 2,
        borderRadius: 2,
        border: "1px solid #e0e0e0",
      }}
    >
      <CardContent>
        <Typography
          variant="h6"
          component="div"
          sx={{ mb: 3, fontWeight: 500 }}
        >
          Transaction Details
        </Typography>
        <Grid
          container
          spacing={3}
          sx={{
            bgcolor: "#fafafa",
            p: 3,
            borderRadius: 1,
            mx: 0,
            width: "100%",
          }}
        >
          <Grid item xs={12} md={4}>
            <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
              <Box>
                <Typography variant="subtitle2" color="text.secondary">
                  Payment Method
                </Typography>
                <Typography variant="body1" fontWeight={500}>
                  {capitalize(order.paymentMethod)}
                </Typography>
              </Box>
            </Box>
          </Grid>

          {order.paymentMethod === "card" && (
            <>
              <Grid item xs={12} md={4}>
                <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                  <Box>
                    <Typography variant="subtitle2" color="text.secondary">
                      Transaction Status
                    </Typography>
                    <Typography variant="body1" fontWeight={500}>
                      {order.transaction.status}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography variant="subtitle2" color="text.secondary">
                      Transaction Date
                    </Typography>
                    <Typography variant="body1" fontWeight={500}>
                      {dayjs(
                        parseInt(order.transaction.trantime || "0") * 1000
                      ).format("DD/MM/YYYY HH:mm:ss")}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} md={4}>
                <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                  <Box>
                    <Typography variant="subtitle2" color="text.secondary">
                      Card Type
                    </Typography>
                    <Typography variant="body1" fontWeight={500}>
                      {capitalize(order.transaction.cardtype)}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography variant="subtitle2" color="text.secondary">
                      Transaction Code
                    </Typography>
                    <Typography variant="body1" fontWeight={500}>
                      {order.transaction.txcode}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            </>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
}
